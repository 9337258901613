/*
DayPilot Navigator Theme
https://themes.daypilot.org/navigator/theme/woowed
Theme Designer Version: 2020.11.14.39354
*/
.custom_theme_main {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-size: 11px;
}
.custom_theme_main *,
.custom_theme_main *:before,
.custom_theme_main *:after {
  box-sizing: border-box;
}
.custom_theme_line {
  border-bottom: 1px solid #000000;
}
/* month header */
.custom_theme_title,
.custom_theme_titleleft,
.custom_theme_titleright {
  border-top: 1px solid #000000;
  color: #333333;
  background: #e0e4ec;
}
.custom_theme_title {
  text-align: center;
}
.custom_theme_titleleft,
.custom_theme_titleright {
  text-align: center;
}
/* day headers */
.custom_theme_dayheader {
  color: #333333;
  background: #ffffff;
  padding: 0px;
  text-align: center;
}
/* day cells */
.custom_theme_cell {
  color: #000000;
  background: #ffffff;
  text-align: center;
}
.custom_theme_cell_text {
  padding: 0px;
}
.custom_theme_weeknumber {
  color: #000000;
  background: #ffffff;
  text-align: center;
  padding: 0px;
}
.custom_theme_select .custom_theme_cell_box {
  /* background-color: #a5d6a7;
  border: 2px solid #469849;
  opacity: 0.5; */
  border: 2.5px dashed black;
  border-radius: 8px;
}

/*.custom_theme_weekend {*/
/*  color: #000000;*/
/*  background: #e0e4ec;*/
/*  text-align: center;*/
/*  padding: 0px;*/
/*}*/

.navigator-heatmap-cell-1 { background-color: hsl(235, 64%, 100%);}
.navigator-heatmap-cell-2 { background-color: hsl(235, 64%, 85%); }
.navigator-heatmap-cell-3 { background-color: hsl(235, 64%, 80%); }
.navigator-heatmap-cell-4 { background-color: hsl(235, 64%, 75%); }
.navigator-heatmap-cell-5 { background-color: hsl(235, 64%, 70%); }
.navigator-heatmap-cell-6 { background-color: hsl(235, 64%, 65%); color: white; }
.navigator-heatmap-cell-7 { background-color: hsl(235, 64%, 60%); color: white; }
.navigator-heatmap-cell-8 { background-color: hsl(235, 64%, 55%); color: white; }
.navigator-heatmap-cell-9 { background-color: hsl(235, 64%, 50%); color: white; }
.navigator-heatmap-cell-10 { background-color: hsl(235, 64%, 45%); color: white; }
.navigator-heatmap-cell-11 { background-color: hsl(235, 64%, 40%); color: white; }
.navigator-heatmap-cell-12 { background-color: hsl(235, 64%, 35%); color: white; }
.navigator-heatmap-cell-13 { background-color: hsl(235, 64%, 30%); color: white; }
.navigator-heatmap-cell-14 { background-color: hsl(235, 64%, 25%); color: white; }
.navigator-heatmap-cell-15 { background-color: hsl(235, 64%, 20%); color: white; }

.custom_theme_dayother {
  color: gray;
}
.custom_theme_todaybox {
  /* border: 3px dotted #3f51b5; */
  border: 3px solid black;
  /* background-color: #a1adc5;
  opacity: 0.5; */
  border-radius: 8px;
}
.custom_theme_busy {
  font-weight: bold;
}
