/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import url('app/shared/daypilot-themes/custom_theme.css');
@import 'packages/cco-frontend/src/_variables.scss';

/* Locally Host Roboto Font */
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';

/* Locally Host Material Icons */
$material-icons-font-path: '../../../node_modules/material-icons/iconfont/';
@import 'material-icons/iconfont/material-icons';

/* Locally Host Material Symbols */
$material-symbols-font-path: 'material-symbols/';
@import 'material-symbols/outlined';



@tailwind base;
@tailwind components;
@tailwind utilities;

// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$app-accent: mat.m2-define-palette(mat.$m2-pink-palette);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create your Sass color vars (will be available in all the project)
$primary: mat.m2-get-color-from-palette($app-primary);
$accent: mat.m2-get-color-from-palette($app-accent);
$warn: mat.m2-get-color-from-palette($app-warn);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

app-root {
  min-height: 100vh;
  width: 100vw;
  // display: flex;
}

app-root,
app-root * {
  touch-action: pan-y pinch-zoom;
}

mat-row:hover {
  background: #f5f5f5;
}

mat-row.clickable:hover {
  cursor: pointer;
}

mat-row:hover.selected_row {
  background-color: $selected_color;
}

.selected_row {
  background-color: $selected_color !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.table-form-cell .mat-form-field-wrapper {
  padding-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.table-form-cell .mat-form-field-underline {
  bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.no-wrapper > div.mat-mdc-form-field-infix {
  padding-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.no-wrapper > div.mat-form-field-wrapper > .mat-form-field-underline {
  bottom: 0;
}

.detail-body-tab-group {
  min-height: 25vh;
}

.padded-tabs mat-tab-body {
  padding-top: 0.75em;
}

.connection-quality-panel {
  min-width: 300px !important;
  max-width: 400px !important;
}

mat-card.slim
  mat-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  min-height: 36px;
  padding-bottom: 8px;
  padding-top: 8px;
}

mat-card.slim mat-form-field .mat-mdc-form-field-icon-suffix button.mdc-icon-button {
  height: 36px;
  width: 36px;
  padding: 6px;
}

mat-card.semi-slim
  mat-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  min-height: 36px;
  padding-bottom: 8px;
  padding-top: 8px;
}

mat-card.semi-slim mat-form-field .mat-mdc-form-field-icon-suffix button.mdc-icon-button {
  height: 36px;
  width: 36px;
  padding: 6px;
}

.mat-mdc-card.semi-slim.header {
  padding-bottom: 4px;
}

.mat-mdc-card.slim.header {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
}

.mat-mdc-card.slim.content {
  padding-top: 4px;
}

// When a slim content mat-card is followed by a <form><div> instead of by a <form><mat-tab>
.mat-mdc-card.slim.content > form > div {
  margin-top: 12px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-card.slim .mat-tab-label {
  max-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}

.mat-mdc-card.slim .mat-mdc-card-title {
  font-size: 16px;
}

.mat-mdc-card.slim .mat-mdc-card-subtitle {
  margin-bottom: 8px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.slim .mat-form-field-appearance-fill .mat-mdc-form-field-infix {
  padding-bottom: 0.65em;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.slim .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0.65em;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.icon-select .mat-form-field-infix {
  max-width: 48px;
}

.mat-stepper-horizontal.no-pointer .mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-step-header .mat-step-icon-selected {
  background-color: $accent;
}

.mat-stepper-horizontal.slim .mat-horizontal-stepper-header {
  height: auto;
}

.mat-mdc-menu-panel.icon-menu {
  min-width: 10px;
}

.mat-mdc-row.slim {
  min-height: 32px;
}

.mat-mdc-option.full-width-option .mdc-list-item__primary-text {
  margin-right: inherit;
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-select.icon-select .mat-select-trigger {
  transform: translate(0, 5px);
}

.mat-mdc-select-panel.icon-select-panel {
  min-width: 48px !important;
  max-width: 56px !important;
}

mat-table.slim mat-row {
  min-height: 32px;
}

mat-label .mat-mdc-icon-button {
  line-height: 14px;
  width: 14px;
  height: 14px;
}

.multiline-tooltip {
  white-space: pre-line !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.truck-map-label {
  background-color: rgba(255, 255, 255, 0.85);
}

.context-menu-exists:hover {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.option-with-divider:not(:last-child) {
  border-bottom: 2px solid #eeeeee;
}

.error-row-below {
  border-bottom: none;
}

.button-row {
  margin-top: 1rem;
  padding: 0 1rem 0 1rem;
}

@media (max-device-width: 1024px) {
  .button-row-spacer {
    width: 100%;
    height: 5rem;
  }

  .button-row {
    background-color: white;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5em;
  }

  .button-row div {
    flex: 33;
    height: 4rem;
  }

  .button-row div button {
    width: 100%;
    height: 100%;
  }
}

mat-cell:first-of-type.slim-left-padding,
mat-header-cell:first-of-type.slim-left-padding {
  padding-left: 8px;
}

// For compatibility with Angular Material < 15
mat-card {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

markdown ol, markdown ul {
  @apply list-decimal list-outside ml-5; // Restores ordered lists with Tailwind utilities
}

markdown ul {
  @apply list-disc; // Ensures unordered lists have bullets
}

markdown li {
  @apply my-1; // Adds spacing between list items
}

