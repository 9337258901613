// Colours
$green1: #6aa84f;
$blue1: #0d47a1;
$orange1: #ffb74d;
$red1: #d50000;
$purple1: #7b1fa2;
$teal1: #2bbbad;
$brown1: #8d6e63;
$yellow1: #ffee58;
$black1: #000000;
$grey1: #a9a9a9;
$teal2: #008b8b;
$green2: #006400;
$green3: #556b2f;
$blue2: #00bfff;
$purple2: #4b0082;
$grey2: #778899;
$brown2: #ffdead;
$pink1: #dda0dd;
$green4: #9acd32;
$pink2: #fa8072;
$red2: #800000;

// Truck colours
$truck0: $green1;
$truck1: $blue1;
$truck2: $orange1;
$truck3: $red1;
$truck4: $purple1;
$truck5: $teal1;
$truck6: $brown1;
$truck7: $yellow1;
$truck8: $black1;
$truck9: $grey1;
$truck10: $teal2;
$truck11: $green2;
$truck12: $green3;
$truck13: $blue2;
$truck14: $purple2;
$truck15: $grey2;
$truck16: $brown2;
$truck17: $pink1;
$truck18: $green4;
$truck19: $pink2;
$truck20: $red2;

// Angular colours
$primary_color: #3f51b5;
$primary_color2: #5c6bc0;
$primary_color3: #7986cb;
$primary_color4: #9fa8da;
$primary_color5: #c5cae9;
$secondary_color: #e91e63;
$warn_color: #f44336;

// Other colours
$selected_color: aliceBlue;
$white: white;
$off_white: whiteSmoke;
$off_white_2: #e8eaf6;
$grey_border: #d7d7d7;

// Scheduler colours
$job: #1066a8;

// flowchart colours
$node0: $green1;
$node1: $blue1;
$node2: $yellow1;
$node3: $teal1;
$node4: $purple1;
$node5: $blue2;
$node6: $brown1;
$node7: $green2;
$node8: $grey2;
$node9: $black1;
$nodeLabel: $orange1;
$nodeLeaf: $secondary_color;
